<template>
  <v-container id="register" fill-height tag="section">
    <v-row justify="center">
      <v-col cols="12" md="4">
        <v-slide-y-transition appear>
          <v-card class="pa-3 pa-md-5 mx-auto" light>
            <pages-heading class="text-center text-h5 mb-4">
              Register
            </pages-heading>

            <v-row>
              <v-col cols="12" md="12">
                <div class="text-center">
                  <!-- <v-btn
                    v-for="(social, i) in socials"
                    :key="i"
                    :color="social.iconColor"
                    class="my-2 mr-1"
                    dark
                    depressed
                    fab
                    small
                  >
                    <v-icon v-text="social.icon" />
                  </v-btn>

                  <div class="my-2" />

                  <div class="text-center grey--text body-1 font-weight-light">
                    If you meet any issues.Please contact us
                  </div> -->

                  <v-alert
                    dense
                    outlined
                    type="error"
                    class="mt-3"
                    v-if="alertBox"
                  >
                    {{ errorText }}
                  </v-alert>

                  <v-alert
                    dense
                    outlined
                    type="success"
                    class="mt-3"
                    v-if="successBox"
                  >
                    {{ successText }}
                  </v-alert>

                  <v-text-field
                    color="secondary"
                    label="Name..."
                    prepend-icon="mdi-account-circle-outline"
                    v-model="name"
                    :error-messages="nameErrors"
                    required
                    @input="$v.name.$touch()"
                    @blur="$v.name.$touch()"
                  />

                  <v-text-field
                    color="secondary"
                    label="Email..."
                    prepend-icon="mdi-email-outline"
                    v-model="email"
                    :error-messages="emailErrors"
                    required
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                  />

                  <v-text-field
                    color="secondary"
                    label="Phone"
                    prepend-icon="mdi-phone-outline"
                    v-model="contact"
                    :error-messages="contactErrors"
                    required
                    @input="$v.contact.$touch()"
                    @blur="$v.contact.$touch()"
                  />

                  <v-text-field
                    color="secondary"
                    label="Password..."
                    v-model="password"
                    prepend-icon="mdi-lock-outline"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    :error-messages="passwordErrors"
                    required
                    @input="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                  />

                  <v-text-field
                    color="secondary"
                    label="Company Name..."
                    v-model="companyName"
                    prepend-icon="mdi-briefcase-outline"
                    :error-messages="companyNameErrors"
                    required
                    @input="$v.companyName.$touch()"
                    @blur="$v.companyName.$touch()"
                  />

                  <v-select
                    color="secondary"
                    v-model="industry"
                    :items="categories"
                    item-color="secondary"
                    label="Business Categories..."
                    prepend-icon="mdi-map-outline"
                    :error-messages="industryErrors"
                    required
                    @input="$v.industry.$touch()"
                    @blur="$v.industry.$touch()"
                  ></v-select>

                  <!-- <v-text-field
                    color="secondary"
                    label="Company Address..."
                    v-model="address"
                    prepend-icon="mdi-map-marker-outline"
                    :error-messages="addressErrors"
                    required
                    @input="$v.address.$touch()"
                    @blur="$v.address.$touch()"
                  /> -->

                  <v-checkbox
                    v-model="checkbox"
                    :error-messages="checkboxErrors"
                    required
                    @input="$v.checkbox.$touch()"
                    @blur="$v.checkbox.$touch()"
                    color="secondary"
                  >
                    <template v-slot:label>
                      <div>
                        I agree with
                        <a
                          target="_blank"
                          href="https://channelsoft.com.my/privacy-policy/"
                          @click.stop
                          class="secondary--text"
                        >
                          terms and conditions
                        </a>
                      </div>
                    </template>
                  </v-checkbox>

                  <pages-btn
                    color="secondary"
                    class="white--text"
                    @click="register()"
                  >
                    Sign Up
                  </pages-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import { validationMixin } from "vuelidate";
import {
  required,
  numeric,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
export default {
  name: "PagesRegister",

  mixins: [validationMixin],

  validations: {
    name: { required },
    email: {
      required,
      email,
      exist(value) {
        return new Promise((resolve, reject) => {
          const params = new URLSearchParams();
          params.append("checkEmailExist", "done");
          params.append("email", this.email);
          axios({
            method: "post",
            url: this.domain + "/user/index.php",
            data: params,
          })
            .then((response) => {
              value = "2";
              const email = response.data.status;
              resolve(email == "2" && email == value);
            })
            .catch(() => reject(false));
        });
      },
    },
    contact: {
      required,
      numeric,
      minLength: minLength(10),
      maxLength: maxLength(11),
    },
    password: { required, minLength: minLength(8) },
    companyName: { required },
    industry: { required },
    // address: { required },
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },

  components: {
    PagesBtn: () => import("./components/Btn"),
    PagesHeading: () => import("./components/Heading"),
  },

  data: () => ({
    socials: [
      {
        href: "#",
        icon: "mdi-twitter",
        iconColor: "#1DA1F2",
      },
      {
        href: "#",
        icon: "mdi-google",
        iconColor: "#ea4c89",
      },
      {
        href: "#",
        icon: "mdi-facebook",
        iconColor: "#3B5998",
      },
    ],
    domain: BASEURL,
    contact: "",
    name: "",
    email: "",
    password: "",
    categories: ["F&B", "Services"],
    industry: "",
    companyName: "",
    address: "",
    alertBox: false,
    errorText: "",
    successBox: false,
    successText: "",
    checkbox: false,
    showPassword: false,
    device_id: "",
  }),
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      !this.$v.email.exist && errors.push("E-mail is existed");
      return errors;
    },

    contactErrors() {
      const errors = [];
      if (!this.$v.contact.$dirty) return errors;

      !this.$v.contact.numeric &&
        errors.push("Contact must consist only number");
      !this.$v.contact.minLength &&
        errors.push("Contact must at least 10 number");
      !this.$v.contact.maxLength &&
        errors.push("Contact maxinmum consist of 11 number");
      !this.$v.contact.required && errors.push("Contact is required");

      return errors;
    },

    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required.");
      !this.$v.password.minLength &&
        errors.push("Password must at least 8 character.");
      return errors;
    },

    companyNameErrors() {
      const errors = [];
      if (!this.$v.companyName.$dirty) return errors;
      !this.$v.companyName.required && errors.push("Company Name is required.");
      return errors;
    },

    industryErrors() {
      const errors = [];
      if (!this.$v.industry.$dirty) return errors;
      !this.$v.industry.required && errors.push("Industry is required");
      return errors;
    },

    // addressErrors() {
    //   const errors = [];
    //   if (!this.$v.address.$dirty) return errors;
    //   !this.$v.address.required && errors.push("Address is required.");
    //   return errors;
    // },
    checkboxErrors() {
      const errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      !this.$v.checkbox.checked && errors.push("You must agree to continue!");
      return errors;
    },
  },
  created() {},
  watch: {},
  methods: {
    create() {
      const params = new URLSearchParams();
      params.append("create", "done");
      params.append("name", this.name);
      params.append("email", this.email);
      params.append("contact", this.contact);
      params.append("password", this.password);
      params.append("company_name", this.companyName);
      params.append("industry", this.industry);
      params.append("address", this.address);
      params.append("password", this.password);
      params.append("device_id", this.device_id);

      axios({
        method: "post",
        url: this.domain + "/user/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            console.log("register successfully");
            this.successBox = true;
            this.successText = "Register successfully";
          } else if (response.data.status == "User Existed!") {
            console.log("Email existed");
            this.alertBox = true;
            this.errorText = "Email has been registered";
          } else if (response.data.status == "Add Company Failed!") {
            this.alertBox = true;
            this.errorText = "Add company fail!";
          } else {
            console.log("check your information");
            this.alertBox = true;
            this.errorText = "something went wrong";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    register() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const params = new URLSearchParams();
        params.append("addDevice", "done");
        params.append("company_name", this.companyName);
        params.append("email", this.email);

        axios({
          method: "post",
          url: "https://cashmax-connect.suriaborneo.com/api/device/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.device_id = response.data.device_id;
              this.create();
            } else {
              console.log("fail to create device");
              this.alertBox = true;
              this.errorText = "fail to create device";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>
<style>
.v-text-field__details {
  margin-top: 5px;
}
</style>

